/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { findById } from '../../utils/methods';
import { getDate, isAfter90Days } from '../../utils/getDate';
import { LICENSE_ASSIGNMENT } from '../../utils/constants';
import {
  accountStatus, defaultFilterValue, featureUsageModeConst, fixedWatchListData, initialFilterValue,
} from './accountListConfig';
import useToggle from '../../hooks/useToggle';

const useAccountList = (props) => {
  const {
    filters,
    showAdvanceFilter,
    isNavigatedFromRiskAccount,
    selectedAlert,
    watchListItem,
  } = props;

  const [toggleAdvanceFilters, setToggleAdvanceFilters] = useToggle(filters.toggleAdvanceFilter ?? showAdvanceFilter);

  // State to store API data ----->
  const [accountList, setAccountList] = useState({
    data: [],
    error: '',
    loading: true,
  });

  const [featureList, setFeatureList] = useState({
    data: [],
    error: '',
    loading: true,
  });

  const [featureUsageAccounts, setFeatureUsageAccounts] = useState({
    data: [],
    error: '',
    loading: true,
  });

  const accountListData = useSelector((state) => state.accountsList);
  const featureData = useSelector((state) => state.featuresList);
  const featureUsageAccountData = useSelector((state) => state.featureUsageAccounts);
  const customFilterViewList = useSelector((state) => state.customFilterView.data);
  const customFilterData = useSelector((state) => state.getCustomAlertDetails.data);
  const highRiskAccountConfig = useSelector((state) => state.highRiskAccountConfig?.data);

  // States & functions ----->
  const [selectedFilters, setSelectedFilters] = useState({ ...initialFilterValue, ...filters });
  const [openDialog, setOpenDialog] = useToggle();
  let selectedViewValue;
  if (selectedAlert) {
    selectedViewValue = selectedAlert;
  } else if (watchListItem) {
    selectedViewValue = watchListItem;
  } else {
    selectedViewValue = defaultFilterValue;
  }
  const [selectedView, setSelectedView] = useState(selectedViewValue);

  const findSelectedView = (id) => {
    return findById(id, [...customFilterViewList, ...fixedWatchListData]);
  };

  const resetFilters = () => {
    setSelectedFilters(initialFilterValue);
    setSelectedView(defaultFilterValue);
  };

  const isRiskAccountListFn = (filterAccountList) => {
    const isRiskAccountList = isAfter90Days(filterAccountList);
    return isRiskAccountList;
  };

  const isHealthScoreMatchFn = (healthScore) => {
    let isHealthScoreMatch = selectedFilters.healthScoreRange || defaultFilterValue;
    if (selectedFilters.healthScoreRange && selectedFilters.healthScoreRange.length) {
      isHealthScoreMatch = healthScore >= selectedFilters.healthScoreRange[0] && healthScore <= selectedFilters.healthScoreRange[1];
    }
    return isHealthScoreMatch;
  };

  const isLicenceAssignmentMatchFn = (assignedSeatsPercent, totalSeats) => {
    let isLicenceAssignmentMatch = selectedFilters.licenceAssignment === defaultFilterValue;
    if (selectedFilters.licenceAssignment === LICENSE_ASSIGNMENT.SITE_WIDE_LICENSE) {
      isLicenceAssignmentMatch = totalSeats <= -1;
    }
    if (selectedFilters.licenceAssignment === LICENSE_ASSIGNMENT.LESS_25) {
      isLicenceAssignmentMatch = totalSeats > -1 && assignedSeatsPercent >= 0 && assignedSeatsPercent <= 25;
    }
    if (selectedFilters.licenceAssignment === LICENSE_ASSIGNMENT.FROM_25_50) {
      isLicenceAssignmentMatch = assignedSeatsPercent > 25 && assignedSeatsPercent <= 50;
    }
    if (selectedFilters.licenceAssignment === LICENSE_ASSIGNMENT.FROM_50_75) {
      isLicenceAssignmentMatch = assignedSeatsPercent > 50 && assignedSeatsPercent <= 75;
    }
    if (selectedFilters.licenceAssignment === LICENSE_ASSIGNMENT.FROM_75_100) {
      isLicenceAssignmentMatch = assignedSeatsPercent > 75;
    }
    return isLicenceAssignmentMatch;
  };

  const isLicenceStatusMatchFn = (status, renewalDate) => {
    const currentDate = getDate();
    const isNeverExpire = renewalDate === null;
    const isRenewalDateValid = renewalDate && getDate(renewalDate) >= currentDate.setHours(0, 0, 0, 0);

    switch (selectedFilters.licenceStatus) {
      case accountStatus.ACTIVE:
        return (status === 'Active') && (isRenewalDateValid || isNeverExpire);

      case accountStatus.TRIAL:
        return (status === 'Trial') && (isRenewalDateValid || isNeverExpire);

      case accountStatus.EXPIRED:
        return (status === 'Active' || status === 'Trial') && (!isRenewalDateValid && !isNeverExpire);

      case accountStatus.CHURNED:
        return (status === 'Uninstalled');

      case accountStatus.SUSPENDED:
        return (status === 'Suspended');

      case accountStatus.ACTIVE_TRIAL:
        return (status === 'Active' || status === 'Trial') && (isRenewalDateValid || isNeverExpire);

      default:
        return false;
    }
  };

  const isFeatureUsageAccountsMatchFn = (orgId) => {
    let isFeatureUsageAccountsMatch = selectedFilters.featureUsageFilter === defaultFilterValue;
    const hasFeatureUsageAccountsData = orgId && featureUsageAccounts?.data?.length;
    if (selectedFilters.featureUsageMode === featureUsageModeConst.USE) {
      isFeatureUsageAccountsMatch = hasFeatureUsageAccountsData && featureUsageAccounts.data.includes(orgId);
    } else if (selectedFilters.featureUsageMode === featureUsageModeConst.DONT_USE) {
      isFeatureUsageAccountsMatch = hasFeatureUsageAccountsData && !featureUsageAccounts.data.includes(orgId);
    }
    return isFeatureUsageAccountsMatch;
  };

  const isWithinDateRange = (date, startDate, endDate) => {
    if (!startDate || !endDate) {
      return true;
    }

    const currentDate = new Date(date);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return currentDate >= start && currentDate <= end;
  };

  const filteredAccounts = useMemo(() => {
    return accountList.data.filter((item) => {
      const {
        healthScore, healthScoreTrend, assignedSeatsPercent, weeklyTrend, installDate,
      } = item;
      const isHealthScoreMatch = isHealthScoreMatchFn(item.healthScore);
      const isLicenceAssignmentMatch = (selectedFilters.licenceAssignment === defaultFilterValue ? true : isLicenceAssignmentMatchFn(item.assignedSeatsPercent, item.totalSeats));
      const isLicenceStatusMatch = selectedFilters.licenceStatus === defaultFilterValue || isLicenceStatusMatchFn(item.status, item.renewalDate);
      const isFeatureUsageAccountsMatch = isFeatureUsageAccountsMatchFn(item.orgId);

      const isStable = selectedFilters.healthScoreTrend === 'stable'
        ? (!(installDate && isAfter90Days(installDate))
          && !eval(highRiskAccountConfig?.highRiskAccount?.condition ?? 'healthScore <= 30'))
        : true;

      const isWatchListAccounts = selectedFilters.healthScoreTrend === defaultFilterValue || ((customFilterData?.[0]?.view === 'weekly' ? item.weeklyTrend === selectedFilters.healthScoreTrend : item.healthScoreTrend === selectedFilters.healthScoreTrend) && isStable);

      const isPackageVersionMatch = selectedFilters.packageVersion === defaultFilterValue || item.packageVersion === selectedFilters.packageVersion;
      const isSalesforceInstanceMatch = selectedFilters.salesforceInstance === defaultFilterValue || item.salesforceInstance === selectedFilters.salesforceInstance;
      const isAccountVisibilityMatch = selectedFilters.accountVisibility === defaultFilterValue || item.visibility === selectedFilters.accountVisibility;
      const isCountryMatch = selectedFilters.country === defaultFilterValue || item.countryCode === selectedFilters.country;
      const isLanguageMatch = selectedFilters.language === defaultFilterValue || item.languageLocale === selectedFilters.language;
      const isOrganisationEditionMatch = selectedFilters.organisationEdition === defaultFilterValue || item.orgEdition === selectedFilters.organisationEdition;
      const isAssignedSeatsPercent = (selectedFilters.range[0] >= 0 && selectedFilters.range[0] <= 100) ? (item.seatsAssigned >= selectedFilters.range[0] && item.seatsAssigned <= (selectedFilters.range[1] >= 100 ? 100000 : selectedFilters.range[1])) : (item.seatsAssigned > 100);

      const isActivationDateRangeMatch = isWithinDateRange(
        item.activationDate,
        selectedFilters.activationStartDate,
        selectedFilters.activationEndDate,
      );
      const isRenewalDateRangeMatch = isWithinDateRange(
        item.renewalDate,
        selectedFilters.renewalStartDate,
        selectedFilters.renewalEndDate,
      );
      const isSearchMatch = selectedFilters.searchQuery === ''
        || (item.accountName && item.accountName.toLowerCase()
          .includes(selectedFilters.searchQuery.toLowerCase()))
        || (item.orgId && item.orgId.includes(selectedFilters.searchQuery));
      const isRiskAccountList = isRiskAccountListFn(item.installDate);

      return (
        isLicenceAssignmentMatch
        && isLicenceStatusMatch
        && isPackageVersionMatch
        && isSalesforceInstanceMatch
        && isAccountVisibilityMatch
        && isCountryMatch
        && isLanguageMatch
        && isOrganisationEditionMatch
        && isAssignedSeatsPercent
        && isActivationDateRangeMatch
        && isSearchMatch
        && isRenewalDateRangeMatch
        && isFeatureUsageAccountsMatch
        && isHealthScoreMatch
        && isWatchListAccounts
        && (isNavigatedFromRiskAccount ? (!isRiskAccountList) : true)
        && eval(selectedFilters?.condition ?? true)
      );
    });
  }, [selectedFilters, accountList.data, featureUsageAccounts.data, highRiskAccountConfig]);

  // State setter functions ----->
  const setAccountListData = () => {
    const { accountsList, loading } = accountListData;
    const localAccountList = accountsList ?? [];
    setAccountList({ ...accountList, data: localAccountList, loading });
  };

  const setFeatureData = () => {
    const { response, loading } = featureData;
    setFeatureList({ ...featureList, data: response?.responseArray, loading });
  };

  const setFeatureUsageAccountData = () => {
    const { data, loading, error } = featureUsageAccountData;
    setFeatureUsageAccounts({
      ...featureUsageAccounts, data, loading, error,
    });
  };

  useEffect(() => {
    setAccountListData();
  }, [accountListData.accountsList]);

  useEffect(() => {
    setFeatureData();
  }, [featureData]);

  useEffect(() => {
    setFeatureUsageAccountData();
  }, [featureUsageAccountData]);

  return {
    accountList,
    filteredAccounts,
    featureList,
    selectedFilters,
    setSelectedFilters,
    resetFilters,
    toggleAdvanceFilters,
    setToggleAdvanceFilters,
    findSelectedView,
    openDialog,
    setOpenDialog,
    selectedView,
    setSelectedView,
  };
};

export default useAccountList;
