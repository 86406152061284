import {
  Box, Button, Stack, Tooltip, Typography,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PackageSelection } from '../../Accounts/components/PackageSelection';
import { CustomDropdown } from '../../../components/CustomDropdown';
import { exportData } from '../../../utils/methods';
import useCustomFilter from './useCustomFilter';

function AccountListHeader(props) {
  const {
    classes,
    accountList,
    packages,
    activePackage,
    handleDropdownChange,
    setSelectedFilters,
    selectedView,
    setSelectedView,
    findSelectedView,
    setToggleAdvanceFilters,
  } = props;

  const {
    customFilters,
    handleCustomView,
  } = useCustomFilter({
    setSelectedFilters, setSelectedView, findSelectedView, setToggleAdvanceFilters,
  });

  return (
    <Box className={classes.headerStyles}>
      <Stack direction="row" alignItems="flex-start" gap={2}>
        <Box className="tg-filter-view">
          <PackageSelection
            dropDownList={packages}
            activeDropdown={activePackage}
            handleChange={handleDropdownChange}
          />
          <Typography sx={{ ml: 1, fontSize: 14, color: (theme) => theme.palette.secondary.mainColor }}>
            {packages?.length > 1 ? 'Packages' : 'Package'}
          </Typography>
        </Box>
        <Box className="tg-filter-view">
          <CustomDropdown background value={selectedView} data={customFilters} onChange={handleCustomView} />
          <Typography sx={{ ml: 1, fontSize: 14, color: (theme) => theme.palette.secondary.mainColor }}>
            {accountList ? `${accountList.length} Records` : 'Loading...'}
          </Typography>
        </Box>
      </Stack>
      <Tooltip title="Export filtered accounts list as excel">
        <Button
          className="tg-export-list"
          variant="outlined"
          endIcon={<FileDownloadIcon color="primary" fontSize="small" />}
          onClick={() => exportData('Accounts', activePackage.packageLabel, accountList)}
        >
          Export
        </Button>
      </Tooltip>
    </Box>
  );
}

export default AccountListHeader;
